//==========================================================
// Web Service BaseURL
//==========================================================


const TimeZone = {
    ukTimeZone: "Europe/London",
    pkTimeZone: "Asia/Karachi",
	aeTimeZone: "Asia/Dubai"
};

 export const parentCountryCode = "+44"

/*

const BaseURLs = {
     coreApiURL : "https://api.test.supermeal.co.uk/api",
     baseImageURL: "https://cdn.superme.al/s/uk",
     baseURL: "https://test.supermeal.co.uk",
     clientId: "postman",
     clientSecret : "test123",
	 distanceUnit: "mile(s)",
	countryCode: "+44",
	timeZone : TimeZone.ukTimeZone,
    currencySymbol: "£",
	SupportEmail:'Support@supermeal.co.uk',
	GoogleAPIkEY: 'AIzaSyBfpBhe3zDJF1n9-kEP94rDXDXgz2YXeSA',
	Platform:'core' //superShoply //core
 };
 
 

const BaseURLs = {
    coreApiURL : "https://api.supermeal.ae/api",
    baseImageURL: "https://cloud.superme.al/p/ae",
    baseURL: "https://www.supermeal.ae",
    clientId: "243E1018-0F45-49EB-BDBD-C0473E27CA88",
    clientSecret : "f5242dfa70c1f9c9f7c",
	currencySymbol : "AED ",
	distanceUnit: "km",
	countryCode: "+971",
	timeZone : TimeZone.aeTimeZone,
	SupportEmail:'Support@supermeal.ae',
	GoogleAPIkEY: 'AIzaSyBtLEdCS3wRaOtOAmEcWyz6xUw-siKAVuI',
	Platform:'core', //superShoply //core
	LiveOrderUrl: 'https://orders.supermeal.ae/account/login',
	DefaultLatLong : [25.19284309,55.27899696],
	DateFormat: "dd/MM/yyyy"
};




const BaseURLs = {
    coreApiURL : "https://api.staging.supermeal.ae/api",
    baseImageURL: "https://cdn.superme.al/s/ae",
    baseURL: "https://staging.supermeal.ae",
    clientId: "postman",
     clientSecret : "test123",
	currencySymbol : "AED ",
	distanceUnit: "km",
	countryCode: "+971",
	timeZone : TimeZone.aeTimeZone,
	SupportEmail:'Support@supermeal.ae',
	GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
	Platform:'core',
	LiveOrderUrl: '',
    DefaultLatLong : [25.19284309,55.27899696]	

};

 const BaseURLs = {
     coreApiURL : "https://api.supershoply.com/api",
     baseImageURL: "https://cdn.superme.al/s/shoply",
     baseURL: "https://wwww.supershoply.com",
     clientId: "postman",
     clientSecret : "test123",
	 distanceUnit: "mile(s)",
	countryCode: "+44",
	timeZone : TimeZone.ukTimeZone,
    currencySymbol: "£",
    SupportEmail:'Support@supershoply.com',
    GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
    Platform:'superShoply', //superShoply //core
	LiveOrderUrl: ''	
 };

 const BaseURLs = {
     coreApiURL : "https://api.supermeal.pk/api",
     baseImageURL: "https://cloud.superme.al/p/pk",
     baseURL: "https://www.supermeal.pk",
     clientId: "611B5991-AC79-4124-8A0F-54805C031FF6",
     clientSecret : "df8b68c1b67e47cdbfba1f6e3592be39",
	 distanceUnit: "KM",
	countryCode: "+92",
	timeZone : TimeZone.pkTimeZone,
    currencySymbol: "Rs ",
    SupportEmail:'support@supermeal.pk',
    GoogleAPIkEY: 'AIzaSyC-mXodssdV24vxhHhXJ6to0qhb3HKBti4',
    Platform:'core', //superShoply //core
	LiveOrderUrl: 'https://orders.supermeal.pk/account/login',
	DefaultLatLong : [24.8668057,67.0807009],
	DateFormat: "DD/MM/YYYY"	
 };



const BaseURLs = {
    coreApiURL : "https://api.supermeal.co.uk/api",
    baseImageURL: "https://cloud.superme.al/p/uk",
    baseURL: "https://www.supermeal.co.uk",
    clientId: "10E8293B-8A24-45FF-A0F8-8FDA725663E7",
    clientSecret : "!Supdderdsds!",
	distanceUnit: "mile(s)",
	countryCode: "+44",
	timeZone : TimeZone.ukTimeZone,
    currencySymbol: "£",
	SupportEmail:'Support@supermeal.co.uk',
	GoogleAPIkEY: 'AIzaSyDTLOx_tuNoC_dxbfam-ZvgJRqM-2YMzPU',
	Platform:'core', //superShoply //core
	LiveOrderUrl: 'https://orders.supermeal.co.uk/account/login',
	DefaultLatLong : [52.488656,-1.8872211],
	DateFormat: "DD/MM/YYYY"		
}; 

*/


const BaseURLs = {
    coreApiURL : "https://api.supermeal.ae/api",
    baseImageURL: "https://cloud.superme.al/p/ae",
    baseURL: "https://www.supermeal.ae",
    clientId: "243E1018-0F45-49EB-BDBD-C0473E27CA88",
    clientSecret : "f5242dfa70c1f9c9f7c",
	currencySymbol : "AED ",
	distanceUnit: "km",
	countryCode: "+971",
	timeZone : TimeZone.aeTimeZone,
	SupportEmail:'Support@supermeal.ae',
	GoogleAPIkEY: 'AIzaSyBtLEdCS3wRaOtOAmEcWyz6xUw-siKAVuI',
	Platform:'core', //superShoply //core
	LiveOrderUrl: 'https://orders.supermeal.ae/account/login',
	DefaultLatLong : [25.19284309,55.27899696],
	DateFormat: "dd/MM/yyyy"
};

 
const GlobalData = {

    restaurants_data: {

        Supermeal_dev: {
            isUk: true,
            coreApiURL : BaseURLs.coreApiURL,
            clientApi : { ClientId: BaseURLs.clientId, ClientSecret: BaseURLs.clientSecret},
            baseUrl: BaseURLs.baseURL, 
            baseImageUrl: BaseURLs.baseImageURL,
            currency: BaseURLs.currencySymbol,
            decimalPlaces: 2,
            csvSeperator: '^^^',
            distanceUnit: BaseURLs.distanceUnit,
            timezone: BaseURLs.timeZone,
            countryCode: BaseURLs.countryCode,
            DeliveryCharges: "0",
			dateFormat:BaseURLs.DateFormat,
            DeliveryTime: "45",
            MinimumDeliveryOrder: "10",
            zoneLimit: 5,
			Google_Api_Key: BaseURLs.GoogleAPIkEY,
            SupportEmail: BaseURLs.SupportEmail,
            Google_Lat_Long: BaseURLs.DefaultLatLong,
            Delivery_Zone_Color_Palette : ["#27ae60","#3498db","#f1c40f","#e74c3c","#8e44ad"],
            Campaign_Logo_Image_Width_Height : ["175","55"],
            Campaign_Main_Banner_Width_Height : ["600","318"],
            Campaign_Sub_Banner_Width_Height : ["1920","150"],
            Campaign_App_Sub_Banner_Width_Height : ["600","100"],
            Campaign_Food_Image_Width_Height : ["750","750"],
            Campaign_Background_Image_Width_Height : ["1920","1080"],
            Campaign_App_Background_Image_Width_Height : ["1000","1000"], 
            Category_Image_Width_Height : ["750","150"], 
            Product_Image_Width_Height : ["500","500"],
            Enterprise_Bank_Details : "Account Name:;Account Number:;Bank Name:;Branch Name:;Branch Address:;Swift Code:;",
            Item_Filters : "Brand::;Tags::;Dietry::;VideoURL::;",
            Session_Expire_In_Hours: 8,
            Platform: BaseURLs.Platform,
			liveOrderUrl: BaseURLs.LiveOrderUrl,
			PreOrderAcceptDuration: 90
        }

    }
}

export default GlobalData
